#root .about-section2 {
  border-bottom: 1px solid black;
}
#root .about-section2 > .img-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 314px;
}
#root .about-section2 > .box-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#root .about-section2 > .box-wrapper > .right-box {
  width: 100%;
  padding-top: 79px;
}
#root .about-section2 > .box-wrapper > .right-box > .management-procedure > .title-box {
  color: white;
  background-color: black;
  padding: 10px 23px;
}
#root .about-section2 > .box-wrapper > .right-box > .management-procedure > .circle-wrapper {
  width: 646px;
  margin: 0 auto;
  margin-top: 40px;
  padding-bottom: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  row-gap: 16px;
  -webkit-column-gap: 16px;
     -moz-column-gap: 16px;
          column-gap: 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .about-section2 > .box-wrapper > .right-box > .management-procedure > .circle-wrapper > .circle {
  width: 142px;
  height: 142px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid black;
}
#root .about-section2 > .box-wrapper > .right-box > .management-procedure > .circle-wrapper > .circle > .img-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 52px;
  padding-bottom: 10px;
}
#root .about-section2 > .box-wrapper > .right-box > .management-procedure > .circle-wrapper > .circle > .circle-text {
  font-size: 14px;
  font-weight: 700;
  white-space: pre;
  border-top: 1px solid black;
  padding-top: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  text-align: center;
}
#root .about-section2 > .box-wrapper > .right-box > .management-procedure > .author-img-wrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#root .about-section2 > .box-wrapper > .right-box > .management-procedure > .author-img-wrapper > .author-title-box {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: 13px 23px;
  margin-bottom: 23px;
  font-size: 17px;
  font-weight: 800;
}
#root .about-section2 > .box-wrapper > .right-box > .management-procedure > .author-img-wrapper > .author-imgs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 0px 28px;
}
#root .about-section2 > .box-wrapper > .right-box > .management-procedure > .author-img-wrapper > .author-imgs > .img-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 17px;
     -moz-column-gap: 17px;
          column-gap: 17px;
}
#root .about-section2 > .box-wrapper > .right-box > .management-procedure > .author-img-wrapper > .author-imgs > .link-wrapper {
  width: 800px;
}
#root .about-section2 > .box-wrapper > .right-box > .management-procedure > .author-img-wrapper > .author-imgs > .link-wrapper > .button {
  width: 196px;
  height: 43px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 138px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 1px solid black;
  font-size: 16px;
  font-weight: 700;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 6px 0px;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .about-section2 > .box-wrapper > .right-box > .management-procedure > .circle-wrapper {
    width: 525px;
    margin-left: 0;
    padding: 0px 20px;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    margin-bottom: 56px;
  }
  #root .about-section2 > .box-wrapper > .right-box > .management-procedure > .author-img-wrapper > .author-imgs > .img-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 18px;
  }
  #root .about-section2 > .box-wrapper > .right-box > .management-procedure > .author-img-wrapper > .author-imgs > .link-wrapper {
    width: auto;
  }
  #root .about-section2 > .box-wrapper > .right-box > .management-procedure > .author-img-wrapper > .author-imgs > .link-wrapper > .button {
    margin-left: 0;
  }
}
@media screen and (max-width: 767px) and (min-width: 360px) {
  #root .about-section2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .about-section2 > .img-wrapper {
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .about-section2 > .box-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .about-section2 > .box-wrapper > .right-box > .management-procedure > .circle-wrapper {
    width: 360px;
    row-gap: 32px;
    padding-bottom: 56px;
  }
  #root .about-section2 > .box-wrapper > .right-box > .management-procedure > .author-img-wrapper > .author-imgs {
    padding: 0px 20px;
  }
  #root .about-section2 > .box-wrapper > .right-box > .management-procedure > .author-img-wrapper > .author-imgs > .img-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 32px;
  }
  #root .about-section2 > .box-wrapper > .right-box > .management-procedure > .author-img-wrapper > .author-imgs > .link-wrapper {
    width: 100%;
  }
  #root .about-section2 > .box-wrapper > .right-box > .management-procedure > .author-img-wrapper > .author-imgs > .link-wrapper > .button {
    width: 100%;
  }
}