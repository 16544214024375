@charset "UTF-8";
#root .residency-card {
  width: 320px;
  height: 320px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  cursor: pointer;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 21px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-size: cover;
  -o-object-fit: cover;
     object-fit: cover;
  background-size: cover;
  background-position: center;
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  position: relative;
}
#root .residency-card > .residency-card-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#root .residency-card > .background-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0.4)));
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
}
#root .residency-card > .author-info {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 4px;
}
#root .residency-card > .author-info > .author-name {
  color: white;
  font-size: 24px;
  font-weight: 700;
  text-align: start;
}
#root .residency-card > .author-info > .author-subtitle {
  color: white;
  font-size: 12px;
  font-weight: 400;
}
#root .residency-card > .detail-btn {
  position: relative;
  z-index: 2;
  width: 109px;
  height: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid white;
  color: white;
  font-size: 16px;
}
#root .zoomed {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  /* 이미지를 1.2배 확대 */
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .residency-card {
    width: auto;
    height: auto;
    aspect-ratio: auto 1/1;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: block;
    position: relative;
  }
  #root .residency-card > .author-info > .author-name {
    font-size: 18px;
  }
  #root .residency-card > .detail-btn {
    position: absolute;
    bottom: 17px;
    right: 16px;
  }
}
@media screen and (max-width: 767px) {
  #root .residency-card {
    width: auto;
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
  }
  #root .residency-card > .author-info > .author-name {
    font-size: 18px;
  }
  #root .residency-card > .detail-btn {
    position: absolute;
    bottom: 17px;
    right: 16px;
  }
  #root .resdiency-info-wrapper > .glasses-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-column-gap: 6px;
       -moz-column-gap: 6px;
            column-gap: 6px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding-bottom: 16px;
    border-bottom: 1px solid black;
    z-index: 1;
  }
  #root .resdiency-info-wrapper > .glasses-wrapper > .circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: white;
  }
  #root .resdiency-info-wrapper > .author-wrapper {
    width: 320px;
    padding: 0px 20px;
    margin-top: 16px;
  }
  #root .resdiency-info-wrapper > .author-wrapper > .author-name {
    font-weight: 700;
    font-size: 20px;
  }
  #root .resdiency-info-wrapper > .author-wrapper > .author-description {
    font-size: 12px;
    font-weight: 400;
    line-height: 24.2px;
  }
}