#root .residency-sidebar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-top: 130.1px;
  border-top: 1px solid black;
  width: 100%;
}
#root .residency-sidebar > .text-wrapper {
  padding: 20px;
  margin-left: 17px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .residency-sidebar > .text-wrapper > .residency-name {
  font-size: 24px;
  font-weight: 700;
}
#root .residency-sidebar > .text-wrapper > .description-box {
  margin-top: 16px;
}
#root .residency-sidebar > .text-wrapper > .description-box > .description {
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 18px;
  font-size: 12px;
}
#root .residency-sidebar > .resdiency-empty {
  padding-top: 24px;
  margin: 0 auto;
}
#root .residency-sidebar > .resdiency-empty > .title {
  font-size: 24px;
  font-weight: 700;
}
#root .residency-sidebar > .resdiency-empty > .subtitle {
  font-size: 14px;
  font-weight: 400;
  white-space: pre-wrap;
  line-height: 25.2px;
}