#root .project-img-card {
  position: relative;
}
#root .project-img-card > .project-img {
  width: 100%;
  height: 470px;
  -o-object-fit: cover;
     object-fit: cover;
  display: block;
}
#root .project-img-card > .background-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.6)));
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
}
#root .project-img-card > .info-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  color: white;
  row-gap: 5px;
  position: absolute;
  z-index: 1;
  bottom: 35px;
  left: 34px;
  text-align: left;
}
#root .project-img-card > .info-wrapper > .info-title {
  font-size: 16px;
  font-weight: 700;
}
#root .project-img-card > .info-wrapper > .info-subtitle {
  font-size: 13px;
  font-weight: 400;
}
#root .project-img-card > .info-wrapper > .info-date {
  font-size: 13px;
  font-weight: 700;
}

@media screen and (max-width: 767px) and (min-width: 360px) {
  #root .project-img-card > .info-wrapper {
    left: 20px;
  }
}