#root .project-page {
  padding-top: 80px;
  padding-bottom: 100px;
}
#root .project-page > .now-section > .slider-banner-wrapper {
  margin-top: 24px;
}
#root .project-page > .project-section {
  margin-top: 96px;
}
#root .project-page > .project-section > .search-wrapper {
  width: 1440px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 24px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 auto;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .project-page > .project-section > .search-wrapper > .search-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  height: 50px;
}
#root .project-page > .project-section > .search-wrapper > .search-bar > .search-input {
  width: 279px;
  height: 50px;
  border: 1px solid black;
  background-color: transparent;
  padding: 0px 20px;
}
#root .project-page > .project-section > .search-wrapper > .search-bar > .search-btn img {
  position: absolute;
  top: 50%;
  bottom: 50%;
  right: 9px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -o-object-fit: cover;
     object-fit: cover;
}
#root .project-page > .project-section > .all-project-imgs {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0;
  padding: 0;
  margin: 0;
}
#root .project-page > .project-section > .page-selector-wrapper {
  margin-top: 47px;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .project-page > .now-section > .project-title-wrapper {
    padding: 0 44px;
    -webkit-box-orient: unset;
    -webkit-box-direction: unset;
        -ms-flex-direction: unset;
            flex-direction: unset;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  #root .project-page > .project-section > .search-wrapper {
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 0px 40px;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 767px) {
  #root .project-page > .now-section > .project-title-wrapper {
    padding: 0 20px;
    -webkit-box-orient: unset;
    -webkit-box-direction: unset;
        -ms-flex-direction: unset;
            flex-direction: unset;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  #root .project-page > .project-section {
    width: 100%;
  }
  #root .project-page > .project-section > .search-wrapper {
    width: auto;
    padding: 0px 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .project-page > .project-section > .search-wrapper > .project-title-wrapper {
    width: 100%;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    row-gap: 6px;
  }
  #root .project-page > .project-section > .search-wrapper > .search-bar {
    margin-top: 24px;
    margin-bottom: 20px;
  }
  #root .project-page > .project-section > .search-wrapper > .search-bar > input {
    width: 320px;
  }
  #root .project-page > .project-section > .all-project-imgs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}