#root .about-info-card {
  border-right: 1px solid black;
}
#root .about-info-card > .about-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding: 79px 39px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 15px;
}
#root .about-info-card > .about-box > .about-title {
  font-size: 20px;
  font-weight: 700;
}
#root .about-info-card > .about-box > .about-maintitle {
  white-space: pre;
  font-size: 48px;
  font-weight: 700;
  line-height: 62.4px;
}
#root .about-info-card > .about-box > .about-subtitle {
  white-space: pre;
  font-size: 14px;
  font-weight: 400;
  line-height: 25.2px;
}

@media screen and (max-width: 1439px) {
  #root .about-info-card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  #root .about-info-card > .about-box > .about-title {
    font-size: 17px;
  }
  #root .about-info-card > .about-box > .about-maintitle {
    font-size: 30px;
    line-height: 39px;
  }
}
@media screen and (max-width: 767px) {
  #root .about-info-card {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  #root .about-info-card > .about-box {
    row-gap: 5px;
  }
  #root .about-info-card > .about-box > .about-title {
    font-size: 20px;
  }
  #root .about-info-card > .about-box > .about-maintitle {
    font-size: 24px;
  }
}
@media screen and (max-width: 550px) and (min-width: 360px) {
  #root .about-info-card {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  #root .about-info-card > .about-box {
    padding: 79px 20px;
  }
}