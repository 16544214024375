@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes homedrawCenter {
  from {
    height: 0;
  }
  to {
    height: 93%;
  }
}
@keyframes homedrawCenter {
  from {
    height: 0;
  }
  to {
    height: 93%;
  }
}
@-webkit-keyframes homedrawHeight {
  from {
    height: 0;
  }
  to {
    height: 790px;
  }
}
@keyframes homedrawHeight {
  from {
    height: 0;
  }
  to {
    height: 790px;
  }
}
@-webkit-keyframes homedrawHeight2 {
  from {
    height: 0;
  }
  to {
    height: 752px;
  }
}
@keyframes homedrawHeight2 {
  from {
    height: 0;
  }
  to {
    height: 752px;
  }
}
@-webkit-keyframes homedrawWidth {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes homedrawWidth {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
#root .home-page {
  padding-top: 64px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .home-page .home-top-wrapper {
  position: relative;
  height: 528px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .home-page .home-top-wrapper > .banner {
  -webkit-animation: fadeIn 1s forwards;
          animation: fadeIn 1s forwards;
}
#root .home-page .home-top-wrapper > .line-container {
  width: 1920px;
  position: relative;
  height: 100%;
  margin-bottom: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-animation: fadeOut 2s forwards;
          animation: fadeOut 2s forwards;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}
#root .home-page .home-top-wrapper > .line-container .line-logo-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 20px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transform: translateY(33px);
          transform: translateY(33px);
}
#root .home-page .home-top-wrapper > .line-container .line-logo-wrapper > .line-title {
  font-size: 18px;
  font-weight: bold;
}
#root .home-page .home-top-wrapper > .line-container .line-logo-wrapper > img {
  position: absolute;
  bottom: 33px;
}
#root .home-page .home-top-wrapper > .line-container .line {
  position: absolute;
  background-color: black;
}
#root .home-page .home-top-wrapper > .line-container .line1 {
  left: 328px;
  top: 0;
  width: 1px;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  -webkit-transform: rotate(-50.91deg);
          transform: rotate(-50.91deg);
  -webkit-animation: homedrawHeight 1.5s ease-in-out forwards;
          animation: homedrawHeight 1.5s ease-in-out forwards;
}
#root .home-page .home-top-wrapper > .line-container .line2 {
  top: 0;
  margin-left: 7px;
  width: 1px;
  -webkit-animation: homedrawCenter 1.5s ease-in-out forwards;
          animation: homedrawCenter 1.5s ease-in-out forwards;
}
#root .home-page .home-top-wrapper > .line-container .line3 {
  right: 377px;
  top: -1px;
  width: 1px;
  height: 775px;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  -webkit-transform: rotate(49.29deg);
          transform: rotate(49.29deg);
  -webkit-animation: homedrawHeight2 1.5s ease-in-out forwards;
          animation: homedrawHeight2 1.5s ease-in-out forwards;
}
#root .home-page .home-top-wrapper > .line-container .line4 {
  left: 0;
  bottom: 0;
  -webkit-animation: homedrawWidth 1.5s ease-in-out forwards;
          animation: homedrawWidth 1.5s ease-in-out forwards;
  height: 1px;
}
#root .home-page .home-top-wrapper > .line-container .line5 {
  right: 0;
  bottom: 0;
  -webkit-animation: homedrawWidth 1.5s ease-in-out forwards;
          animation: homedrawWidth 1.5s ease-in-out forwards;
  height: 1px;
}
#root .home-page > .kunst-eins {
  -webkit-animation: inside 1.5s ease-in-out;
          animation: inside 1.5s ease-in-out;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 44px 0px;
  border-bottom: 1px solid black;
}

@media screen and (max-width: 1439px) and (min-width: 769px) {
  @-webkit-keyframes homedrawCenter {
    from {
      height: 0;
    }
    to {
      height: 93%;
    }
  }
  @keyframes homedrawCenter {
    from {
      height: 0;
    }
    to {
      height: 93%;
    }
  }
  @-webkit-keyframes homedrawHeight {
    from {
      height: 0;
    }
    to {
      height: 765px;
    }
  }
  @keyframes homedrawHeight {
    from {
      height: 0;
    }
    to {
      height: 765px;
    }
  }
  @-webkit-keyframes homedrawHeight2 {
    from {
      height: 0;
    }
    to {
      height: 756px;
    }
  }
  @keyframes homedrawHeight2 {
    from {
      height: 0;
    }
    to {
      height: 756px;
    }
  }
  @-webkit-keyframes homedrawWidth {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  @keyframes homedrawWidth {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  #root .home-page .home-top-wrapper {
    height: 536px;
  }
  #root .home-page .home-top-wrapper .line-container .line1 {
    left: 312px;
    top: 72px;
    -webkit-transform: rotate(-55.4deg);
            transform: rotate(-55.4deg);
  }
  #root .home-page .home-top-wrapper .line-container .line3 {
    right: 378px;
    -webkit-transform: rotate(48.78deg);
            transform: rotate(48.78deg);
  }
}
@media screen and (max-width: 768px) {
  @-webkit-keyframes homedrawCenter {
    from {
      height: 0;
    }
    to {
      height: 91%;
    }
  }
  @keyframes homedrawCenter {
    from {
      height: 0;
    }
    to {
      height: 91%;
    }
  }
  @-webkit-keyframes homedrawHeight {
    from {
      height: 0;
    }
    to {
      height: 528px;
    }
  }
  @keyframes homedrawHeight {
    from {
      height: 0;
    }
    to {
      height: 528px;
    }
  }
  @-webkit-keyframes homedrawHeight2 {
    from {
      height: 0;
    }
    to {
      height: 505px;
    }
  }
  @keyframes homedrawHeight2 {
    from {
      height: 0;
    }
    to {
      height: 505px;
    }
  }
  #root .home-page .home-top-wrapper {
    height: 422px;
  }
  #root .home-page .home-top-wrapper .line-container .line1 {
    left: 522px;
    top: 72px;
    -webkit-transform: rotate(-52.67deg);
            transform: rotate(-52.67deg);
  }
  #root .home-page .home-top-wrapper .line-container .line3 {
    right: 514.8px;
    top: 122px;
    -webkit-transform: rotate(58.7deg);
            transform: rotate(58.7deg);
  }
}