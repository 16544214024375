#root .residency-page {
  padding-top: 64px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100vw;
  height: 100vh;
}
#root .residency-page > .residency-section {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
}
#root .residency-page > .residency-section .purchase-btn {
  border: 1px solid black;
  font-weight: 700;
  text-align: center;
  width: 321px;
  height: 50px;
  margin-top: 21px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .residency-page > .residency-section > .title-wrapper {
  font-size: 80px;
  font-weight: 400;
  text-align: center;
}
#root .residency-page > .residency-section > .left-wrapper {
  height: calc(100vh - 64px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-right: 1px solid black;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 21%;
          flex: 1 1 21%;
  margin-left: -17px;
  overflow-x: hidden;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
#root .residency-page > .residency-section > .left-wrapper::-webkit-scrollbar {
  display: none;
}
#root .residency-page > .residency-section > .right-wrapper {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 70%;
          flex: 1 1 70%;
}
#root .residency-page > .residency-section > .right-wrapper > .title-wrapper {
  padding: 13px 30px;
  border-bottom: 1px solid black;
}
#root .residency-page > .residency-section > .right-wrapper > .title-wrapper > .title {
  font-size: 80px;
  font-weight: 400;
}
#root .residency-page > .residency-section > .right-wrapper > .img-card-wrapper {
  overflow-x: hidden;
  padding: 24px 20px 120px;
  display: grid;
  gap: 17px;
  grid-template-columns: repeat(3, 320px);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: calc(100vh - 195px);
}
#root .residency-mobile-page {
  padding: 64px 0;
}
#root .residency-mobile-page .author-description {
  white-space: pre-line;
  text-align: left;
  margin-top: 8px;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .residency-page > .residency-section .purchase-btn {
    width: 222px;
  }
  #root .residency-page > .residency-section > .left-wrapper {
    min-width: 290px;
    -webkit-box-flex: 1;
        -ms-flex: 1 40%;
            flex: 1 40%;
    padding-bottom: 100px;
  }
  #root .residency-page > .residency-section > .right-wrapper {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 60%;
            flex: 1 1 60%;
  }
  #root .residency-page > .residency-section > .right-wrapper > .img-card-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 767px) {
  #root .residency-page > .residency-section > .left-wrapper {
    display: block;
    width: 100%;
    padding: 0;
    border-right: none;
  }
  #root .residency-page > .residency-section > .right-wrapper {
    display: block;
    width: 100%;
    padding: 0;
  }
  #root .residency-page > .residency-section > .right-wrapper > .purchase-btn-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-bottom: 1px solid black;
    padding: 16px 0px;
    margin-bottom: 16px;
  }
  #root .residency-page > .residency-section > .right-wrapper > .purchase-btn-box > .purchase-btn {
    margin: unset;
  }
  #root .residency-page > .residency-section > .right-wrapper > .caption-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-bottom: 16px;
  }
  #root .residency-page > .residency-section > .right-wrapper > .caption-wrapper > .title {
    font-weight: 700;
  }
  #root .residency-page > .residency-section > .right-wrapper > .caption-wrapper > .caption {
    font-size: 14px;
    font-weight: 400;
  }
  #root .residency-page > .residency-section > .right-wrapper > .img-card-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0;
    padding-bottom: 120px;
    height: calc(100vh - 260px);
  }
  #root .residency-page > .residency-section > .right-wrapper > .title-wrapper {
    width: 100%;
    padding: 16px 0;
    text-align: center;
  }
  #root .residency-page > .residency-section > .right-wrapper > .title-wrapper > .title {
    font-size: 40px;
  }
}