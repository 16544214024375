#root .residency-slider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-bottom: 1px solid black;
  margin-bottom: 16px;
}
#root .residency-slider > .author-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 10px;
  padding: 16px 0px;
  border-bottom: 1px solid black;
}
#root .residency-slider > .author-wrapper > .author {
  font-size: 20px;
  font-weight: 700;
}
#root .residency-slider > .author-wrapper > .field {
  font-size: 14px;
  font-weight: 400;
}
#root .residency-slider > .slider-wrapper {
  position: relative;
  padding: 16px 0;
}
#root .residency-slider > .slider-wrapper > .slider {
  width: 84%;
  overflow: hidden;
  margin: 0 auto;
}
#root .residency-slider > .slider-wrapper > .slider > .slider-img-box {
  position: relative;
  z-index: 20;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}
#root .residency-slider > .slider-wrapper > .slider > .slider-img-box button {
  width: 96px;
}
#root .residency-slider > .slider-wrapper > .slider > .slider-img-box button > .img {
  width: 96px;
  height: 93px;
}
#root .residency-slider > .slider-wrapper > .slider-btn {
  width: 95%;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .residency-slider > .slider-wrapper > .slider-btn > .btn {
  width: 28px;
  height: 28px;
  border: 1px solid rgba(0, 0, 0, 0.6);
  background-color: rgba(0, 0, 0, 0.6);
}
#root .residency-slider > .slider-wrapper > .slider-btn > .btn img {
  width: 6px;
  height: 12px;
}

@media screen and (max-width: 767px) {
  #root .residency-slider > .slider-wrapper > .slider {
    width: 70vw;
  }
  #root .residency-slider > .slider-wrapper > .slider-btn {
    width: 100vw;
  }
}