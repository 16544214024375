#root .news-list-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-bottom: solid 1px black;
}
#root .news-list-wrapper > .list-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 16px 0px;
  cursor: pointer;
}
#root .news-list-wrapper > .list-wrapper .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
}
#root .news-list-wrapper > .list-wrapper .box > .group-text {
  font-weight: 700;
}
#root .news-list-wrapper > .list-wrapper .box > .list-group {
  font-weight: 700;
}
#root .news-list-wrapper > .list-wrapper .box > .list-title {
  font-weight: 400;
}
#root .news-list-wrapper > .list-wrapper .box > .list-date {
  font-weight: 400;
}
#root .news-list-wrapper > .list-wrapper > .news-title-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .news-list-wrapper > .list-wrapper > .news-title-wrapper > .list-date {
  font-size: 14px;
  font-weight: 400;
}

@media screen and (max-width: 767px) and (min-width: 360px) {
  #root .news-list-wrapper > .list-wrapper {
    padding: 16px 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 8px;
  }
  #root .news-list-wrapper > .list-wrapper > .box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 8px;
  }
  #root .news-list-wrapper > .list-wrapper > .list-date {
    font-size: 12px;
  }
}