#root .project-title-wrapper {
  width: 1440px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: end;
  -webkit-column-gap: 12px;
     -moz-column-gap: 12px;
          column-gap: 12px;
  position: relative;
}
#root .project-title-wrapper > .project-title-now {
  font-size: 50px;
  font-weight: 400;
}
#root .project-title-wrapper > .project-title-text {
  -webkit-transform: translateY(-8px);
          transform: translateY(-8px);
  font-size: 24px;
  font-weight: 400;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .project-title-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}
@media screen and (max-width: 767px) {
  #root .project-title-wrapper {
    width: auto;
    padding: 0px 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}