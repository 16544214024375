#root .project-detail-title-box {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#root .project-detail-title-box > .title-box {
  width: 100%;
  row-gap: 8px;
  padding: 0 20px;
  padding-bottom: 42px;
  border-bottom: solid 1px black;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .project-detail-title-box > .title-box > .title {
  font-size: 30px;
  font-weight: 700;
}
#root .project-detail-title-box > .title-box > .subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 25.2px;
  white-space: pre-wrap;
}
#root .project-detail-title-box > .main-img-box {
  width: 100%;
  margin-top: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 24px;
     -moz-column-gap: 24px;
          column-gap: 24px;
  position: relative;
  padding: 0 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .project-detail-title-box > .main-img-box > .img {
  width: 211px;
  height: 298px;
  -o-object-fit: cover;
     object-fit: cover;
}
#root .project-detail-title-box > .main-img-box > .info-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 10px;
}
#root .project-detail-title-box > .main-img-box > .info-wrapper > .info-text-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
}
#root .project-detail-title-box > .main-img-box > .info-wrapper > .info-text-wrapper > .info-title {
  width: 50px;
  font-size: 13px;
  font-weight: 700;
}
#root .project-detail-title-box > .main-img-box > .info-wrapper > .info-text-wrapper > .info-value {
  font-size: 13px;
  font-weight: 400;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .project-detail-title-box {
    width: 100%;
  }
  #root .project-detail-title-box > .title-box > .title {
    font-size: 24px;
    margin-bottom: 12px;
  }
  #root .project-detail-title-box > .main-img-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .project-detail-title-box > .main-img-box > .info-wrapper {
    margin-top: 16px;
  }
}
@media screen and (max-width: 767px) {
  #root .project-detail-title-box > .title-box {
    width: 100%;
    padding: 0px 20px;
    padding-bottom: 24px;
    border: unset;
  }
  #root .project-detail-title-box > .title-box > .title {
    font-size: 24px;
    margin-bottom: 12px;
  }
  #root .project-detail-title-box > .main-img-box {
    width: 100%;
    border-top: 1px solid black;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 0;
    padding: unset;
  }
  #root .project-detail-title-box > .main-img-box > .img {
    min-width: 360px;
    height: 508px;
    margin: 0 auto;
  }
  #root .project-detail-title-box > .main-img-box > .info-wrapper {
    padding: 16px 20px;
    border-top: 1px solid black;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
}