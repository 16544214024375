#root .news-detail-page {
  padding-top: 147px;
  padding-bottom: 100px;
}
#root .news-detail-page > .news-detail-section {
  width: 1045px;
  margin: 0 auto;
}
#root .news-detail-page > .news-detail-section > .news-detail-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#root .news-detail-page > .news-detail-section > .news-detail-wrapper > .title-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 15px;
}
#root .news-detail-page > .news-detail-section > .news-detail-wrapper > .title-wrapper > .title-text {
  font-size: 24px;
  font-weight: 700;
}
#root .news-detail-page > .news-detail-section > .news-detail-wrapper > .date {
  margin-top: 36px;
  border-bottom: 1px solid black;
  padding-bottom: 10px;
  font-size: 13px;
  font-weight: 400;
}
#root .news-detail-page > .news-detail-section > .news-detail-wrapper > .description {
  padding-top: 16px;
  line-height: 28.8px;
  white-space: pre-line;
}
#root .news-detail-page > .news-detail-section > .img-wrapper {
  margin-top: 24px;
}
#root .news-detail-page > .news-detail-section > .img-wrapper img {
  width: 100%;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .news-detail-page > .news-detail-section {
    width: 687px;
  }
}
@media screen and (max-width: 767px) and (min-width: 360px) {
  #root .news-detail-page > .news-detail-section {
    width: auto;
    padding: 0px 20px;
  }
}