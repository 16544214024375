#root .project-detail-page {
  padding: 64px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 100vh;
  overflow: hidden;
}
#root .project-detail-page > .project-detail-section {
  width: auto;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .project-detail-page > .project-detail-section > .left-box {
  width: 100%;
  padding: 40px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-right: 1px solid black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 40%;
          flex: 1 40%;
}
#root .project-detail-page > .project-detail-section > .right-box {
  height: auto;
  overflow-x: hidden;
  padding: 100px 0;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 60%;
          flex: 1 1 60%;
  z-index: 1;
}
#root .project-detail-page > .project-detail-section > .right-box > .img-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 8px;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .project-detail-page > .project-detail-section > .left-box {
    width: 360px;
  }
  #root .project-detail-page > .project-detail-section > .right-box > .img-box {
    width: 100%;
  }
  #root .project-detail-page > .project-detail-section > .right-box > .img-box img {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  #root .project-detail-page {
    padding: 64px 0;
    overflow: unset;
  }
  #root .project-detail-page > .project-detail-section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: auto;
  }
  #root .project-detail-page > .project-detail-section > .left-box {
    display: block;
    padding-right: 0px;
    margin-bottom: 0;
    padding-bottom: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border: unset;
  }
  #root .project-detail-page > .project-detail-section > .right-box {
    overflow: unset;
    padding-top: unset;
  }
  #root .project-detail-page > .project-detail-section > .right-box > .img-box {
    width: 100%;
    row-gap: 17px;
  }
  #root .project-detail-page > .project-detail-section > .right-box > .img-box > .img {
    width: 100%;
  }
}