#root .list-card {
  width: 100%;
}
#root .list-card > .list-wrapper {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .list-card > .list-wrapper > .title-wrapper {
  width: 100%;
  border-bottom: 1px solid black;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 6px 0px;
  padding-left: 17px;
}
#root .list-card > .list-wrapper > .title-wrapper > .title {
  font-size: 17px;
  font-weight: 700;
}
#root .list-card > .list-wrapper > .list-box {
  padding: 17px 0px;
  padding-left: 17px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 14px;
}
#root .list-card > .list-wrapper > .list-box li {
  list-style-type: decimal;
  list-style-position: inside;
  font-size: 16px;
  font-weight: 400;
  white-space: pre;
}
#root .list-card:first-child {
  border-bottom: 1px solid black;
  border-top: 1px solid black;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .list-card {
    width: 100%;
  }
  #root .list-card > .list-wrapper > .list-box {
    width: 100%;
    padding: 17px 20px;
  }
  #root .list-card > .list-wrapper > .list-box li {
    font-size: 12px;
    line-height: 24px;
  }
}
@media screen and (max-width: 767px) {
  #root .list-card {
    width: 100%;
  }
  #root .list-card > .list-wrapper > .list-box {
    width: 100%;
    padding-bottom: 120px;
  }
  #root .list-card > .list-wrapper > .list-box li {
    font-size: 15px;
    white-space: normal;
  }
}
@media screen and (max-width: 550px) and (min-width: 360px) {
  #root .list-card {
    width: 100%;
  }
  #root .list-card > .list-wrapper > .list-box {
    width: 100%;
    padding-bottom: 120px;
  }
  #root .list-card > .list-wrapper > .list-box li {
    font-size: 15px;
    white-space: pre;
    line-height: 24px;
  }
}