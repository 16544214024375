@charset "UTF-8";
#root .banner {
  width: 100vw;
  height: 528px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
}
#root .banner > .banner-img-wrapper {
  width: 100%;
  overflow: hidden;
  position: relative;
}
#root .banner > .banner-img-wrapper > .banner-slide {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  /* 디졸브 효과 */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .banner > .banner-img-wrapper > .banner-slide.active {
  opacity: 1;
  z-index: 1;
}
#root .banner > .banner-img-wrapper > .banner-slide > .banner-img-box {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}
#root .banner > .banner-img-wrapper > .banner-slide > .banner-img-box > .background-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.6)));
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
}
#root .banner > .banner-img-wrapper > .banner-slide > .banner-img-box > .banner-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#root .banner > .banner-img-wrapper > .banner-slide > .banner-img-box > .content-wrapper {
  width: 100%;
  position: absolute;
  top: 40%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 12px;
  margin: 0 auto;
}
#root .banner > .banner-img-wrapper > .banner-slide > .banner-img-box > .content-wrapper > .banner-project {
  color: white;
  font-size: 16px;
  font-weight: 700;
}
#root .banner > .banner-img-wrapper > .banner-slide > .banner-img-box > .content-wrapper > .banner-title {
  font-size: 32px;
  color: white;
  font-weight: 700;
}
#root .banner > .banner-img-wrapper > .banner-slide > .banner-img-box > .content-wrapper > .banner-subtitle {
  font-size: 14px;
  font-weight: 400;
  color: white;
}
#root .banner > .banner-img-wrapper > .banner-slide > .banner-img-box > .content-wrapper > .banner-date {
  font-size: 14px;
  color: white;
  font-weight: 700;
}
#root .banner > .arrow-btn {
  cursor: pointer;
  top: 50%;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 2;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .banner > .prev-btn {
  left: 3%;
}
#root .banner > .next-btn {
  right: 3%;
}

@media screen and (max-width: 1439px) and (min-width: 769px) {
  #root .banner {
    height: 536px;
  }
}
@media screen and (max-width: 768px) {
  #root .banner {
    height: 422px;
  }
  #root .banner > .banner-img-wrapper > .banner-slide > .banner-img-box > .content-wrapper > .banner-project {
    font-size: 13px;
  }
  #root .banner > .banner-img-wrapper > .banner-slide > .banner-img-box > .content-wrapper > .banner-title {
    font-size: 20px;
  }
  #root .banner > .banner-img-wrapper > .banner-slide > .banner-img-box > .content-wrapper > .banner-subtitle {
    font-size: 12px;
  }
  #root .banner .arrow-btn img {
    height: 40px;
  }
}