#root .header {
  width: 100%;
  height: 64px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 22px 37px 14px 37px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: fixed;
  z-index: 999;
  background-color: var(--main);
  border-bottom: 1px solid black;
}
#root .header > .logo-img {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}
#root .header > .menu-list-wrapper {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  text-align: center;
  -webkit-column-gap: 30px;
     -moz-column-gap: 30px;
          column-gap: 30px;
}
#root .header > .menu-list-wrapper > .list {
  font-size: 16;
  font-weight: 700;
}
#root .header > .menu-wrapper {
  width: 100%;
  height: 100vh;
  left: 0;
  top: 64px;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
#root .header > .menu-wrapper > .menu-lists {
  width: 100%;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  z-index: 1;
  background-color: var(--main);
}
#root .header > .menu-wrapper > .menu-lists > .menu-list {
  padding: 16px 0px;
  text-align: center;
  border-bottom: 1px solid black;
  font-weight: 700;
}
#root .header > .menu-wrapper .background-section {
  height: 100%;
  width: 100%;
  background-color: transparent;
}

@media screen and (max-width: 1439px) {
  #root .header {
    padding: 15px 20px;
  }
}