#root .about-section01 .about-img-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#root .about-section01 > .display-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid black;
}
#root .about-section01 > .display-wrapper > .right-wrapper {
  width: 100%;
  padding: 79px 0px;
}
#root .about-section01 > .display-wrapper > .right-wrapper > .display-img-wrapper > .img-title-wrapper {
  width: 100vw;
  padding: 10px 23px;
  background-color: black;
}
#root .about-section01 > .display-wrapper > .right-wrapper > .display-img-wrapper > .img-title-wrapper > .img-title {
  color: white;
  font-size: 17px;
  font-weight: 700;
}
#root .about-section01 > .display-wrapper > .right-wrapper > .display-img-wrapper > .img-cards {
  width: 1000px;
  padding: 22px 28px;
}
#root .about-section01 > .display-wrapper > .right-wrapper > .display-img-wrapper > .img-cards > .img-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-column-gap: 11px;
     -moz-column-gap: 11px;
          column-gap: 11px;
  row-gap: 16px;
}
#root .about-section01 > .display-wrapper > .right-wrapper > .display-img-wrapper > .img-cards > .button-wrapper {
  width: 730px;
  margin-top: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
#root .about-section01 > .display-wrapper > .right-wrapper > .display-img-wrapper > .img-cards > .button-wrapper > .button {
  height: 43px;
  padding: 7px 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 1px solid black;
}
#root .about-section01 > .display-wrapper > .right-wrapper > .display-img-wrapper > .img-cards > .button-wrapper > .button > .btn-text {
  font-weight: 700;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .about-section01 > .display-wrapper > .right-wrapper {
    width: 100%;
    padding: 52px 0px;
  }
  #root .about-section01 > .display-wrapper > .right-wrapper > .display-img-wrapper > .img-cards {
    width: 360px;
    padding: 40px 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .about-section01 > .display-wrapper > .right-wrapper > .display-img-wrapper > .img-cards > .button-wrapper {
    width: auto;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
@media screen and (max-width: 767px) {
  #root .about-section01 > .about-img-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .about-section01 > .display-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .about-section01 > .display-wrapper > .right-wrapper {
    padding: 0;
  }
  #root .about-section01 > .display-wrapper > .right-wrapper > .display-img-wrapper > .img-cards {
    width: auto;
    margin-top: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 0px 20px;
    row-gap: 16px;
  }
  #root .about-section01 > .display-wrapper > .right-wrapper > .display-img-wrapper > .img-cards > .button-wrapper {
    width: auto;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-bottom: 73px;
  }
}