#root .news-page {
  padding-top: 63px;
}
#root .news-page > .title-wrapper {
  height: 102px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-bottom: 1px solid black;
  margin-bottom: 42px;
}
#root .news-page > .title-wrapper > .title {
  font-size: 50px;
  font-weight: 400;
}
#root .news-page > .news-section {
  width: 1024px;
  margin: 0 auto;
}
#root .news-page > .news-section > .news-lists-wrapper {
  padding: 16px 0px;
}
#root .news-page > .news-section > .news-lists-wrapper > .news-title-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .news-page > .news-section > .news-lists-wrapper > .news-title-wrapper > .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
  font-size: 14px;
}
#root .news-page > .news-section > .news-lists-wrapper > .news-title-wrapper > .box > .group-text {
  width: 60px;
  font-weight: 700;
}
#root .news-page > .news-section > .news-lists-wrapper > .news-lists {
  margin-top: 11px;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}
#root .news-page > .news-section > .page-selector-btn {
  margin-top: 39px;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .news-page > .title-wrapper {
    padding-bottom: 20px;
  }
  #root .news-page > .news-section {
    width: 688px;
  }
}
@media screen and (max-width: 767px) and (min-width: 360px) {
  #root .news-page {
    padding-bottom: 100px;
  }
  #root .news-page > .news-section {
    width: 100%;
  }
  #root .news-page > .news-section > .news-lists-wrapper {
    padding: 0px 20px;
  }
  #root .news-page > .news-section > .news-lists-wrapper > .news-title-wrapper > .mb-title {
    padding: 0;
  }
  #root .news-page > .news-section > .news-lists-wrapper > .news-lists > .news-list-wrapper > .list-wrapper {
    padding: 16px 0px;
  }
}