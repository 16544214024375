#root .about-section3 > .img-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#root .about-section3 > .box-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid black;
}
#root .about-section3 > .box-wrapper > .right-box {
  width: 100%;
  padding-top: 83px;
}
#root .about-section3 > .box-wrapper > .right-box > .inquiry {
  height: 67px;
  background-color: black;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 19px 23px;
  box-sizing: border-box;
  color: white;
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 122px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 16px;
     -moz-column-gap: 16px;
          column-gap: 16px;
}
#root .about-section3 > .box-wrapper > .right-box > .inquiry > .contactus-btn {
  width: 114px;
  height: 43px;
  border: 1px solid white;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .about-section3 > .box-wrapper > .right-box > .inquiry > .contactus-btn > .contactus-text {
  color: white;
  font-size: 16px;
  font-weight: 700;
}

@media screen and (max-width: 767px) {
  #root .about-section3 > .img-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .about-section3 > .box-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    border-bottom: none;
  }
  #root .about-section3 > .box-wrapper > .right-box > .inquiry {
    height: 108px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: unset;
        -ms-flex-pack: unset;
            justify-content: unset;
    -webkit-box-align: unset;
        -ms-flex-align: unset;
            align-items: unset;
    row-gap: 8px;
    margin-bottom: unset;
  }
}