#root .contactus-input-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 4px;
}
#root .contactus-input-wrapper > .contactus-label {
  font-size: 14px;
  font-weight: 700;
  color: #54585A;
}
#root .contactus-input-wrapper > .contactus-input {
  width: 434px;
  height: 50px;
  border: 1px solid #949494;
  padding: 0px 16px;
}

@media screen and (max-width: 1439px) {
  #root .contactus-input-wrapper > .contactus-input {
    width: 100%;
  }
}