#root .page-selector {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 4px;
     -moz-column-gap: 4px;
          column-gap: 4px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .page-selector button {
  width: 29px;
  height: 29px;
}
#root .page-selector > .page-selector-btn {
  border-radius: 2px;
  font-size: 15px;
  font-weight: 600;
  border: 1px solid black;
}