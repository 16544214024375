#root .contactus-page {
  padding-top: 95px;
  padding-bottom: 100px;
}
#root .contactus-page .agreement-btn {
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 5px;
     -moz-column-gap: 5px;
          column-gap: 5px;
  padding: 16px;
  background-color: #F5F6F8;
}
#root .contactus-page .agreement-btn p {
  font-size: 16px;
  font-weight: 400;
}
#root .contactus-page .check-circle {
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 1px solid #949494;
}
#root .contactus-page > .title-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-bottom: 1px solid black;
  padding-bottom: 25px;
}
#root .contactus-page > .title-section > .title-wrapper {
  width: 1085px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 4px;
}
#root .contactus-page > .title-section > .title-wrapper > .contactus-title {
  font-weight: 400;
  font-size: 50px;
}
#root .contactus-page > .title-section > .title-wrapper > .contactus-subtitle {
  font-size: 17px;
  font-weight: 400;
}
#root .contactus-page > .contactus-section {
  border-bottom: 1px solid black;
}
#root .contactus-page > .contactus-section > .contactus-wrapper {
  width: 1085px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 13px;
  padding: 30px 0px;
  margin: 0 auto;
}
#root .contactus-page > .contactus-section > .contactus-wrapper > .contactus-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
}
#root .contactus-page > .contactus-section > .contactus-wrapper > .contactus-box > .contactus-title {
  font-size: 20px;
  font-weight: 400;
}
#root .contactus-page > .contactus-section > .contactus-wrapper > .contactus-box > .call-box {
  width: 68px;
  height: 27px;
  border-radius: 50px;
  background-color: white;
  font-weight: 700;
  font-size: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .contactus-page > .contactus-section > .contactus-wrapper > .kakao-ch-btn {
  width: 381px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: black;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: none;
  -webkit-column-gap: 6px;
     -moz-column-gap: 6px;
          column-gap: 6px;
}
#root .contactus-page > .contactus-section > .contactus-wrapper > .kakao-ch-btn > .kakao-text {
  color: white;
  font-weight: 700;
}
#root .contactus-page > .input-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .contactus-page > .input-section > .inquiry-type-wrapper {
  width: 1085px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 7px;
  margin-top: 35px;
}
#root .contactus-page > .input-section > .inquiry-type-wrapper > .inquiry-text {
  font-size: 14px;
  font-weight: 700;
  color: #54585A;
}
#root .contactus-page > .input-section > .inquiry-type-wrapper > .btn-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 15px;
     -moz-column-gap: 15px;
          column-gap: 15px;
}
#root .contactus-page > .input-section > .inquiry-type-wrapper > .btn-wrapper > .inquiry-btn {
  padding: 10px 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 1px solid black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 5px;
     -moz-column-gap: 5px;
          column-gap: 5px;
}
#root .contactus-page > .input-section > .inquiry-type-wrapper > .btn-wrapper > .inquiry-btn > .btn-title {
  font-weight: 400;
}
#root .contactus-page > .input-section > .inquiry-input-wrapper {
  width: 1085px;
}
#root .contactus-page > .input-section > .inquiry-input-wrapper > .input-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  -webkit-column-gap: 25px;
     -moz-column-gap: 25px;
          column-gap: 25px;
}
#root .contactus-page > .input-section > .inquiry-input-wrapper > .input-wrapper > .left-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 32px;
}
#root .contactus-page > .input-section > .inquiry-input-wrapper > .input-wrapper > .right-box {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#root .contactus-page > .input-section > .inquiry-input-wrapper > .input-wrapper > .right-box > .description-text {
  font-size: 14px;
  font-weight: 700;
  color: #54585A;
  margin-bottom: 4px;
}
#root .contactus-page > .input-section > .inquiry-input-wrapper > .input-wrapper > .right-box > .description {
  width: 100%;
  height: 264px;
  padding: 11px 16px;
  color: black;
  border: 1px solid #949494;
}
#root .contactus-page > .input-section > .inquiry-input-wrapper > .input-wrapper > .right-box > .submit-btn {
  background-color: black;
  color: white;
  height: 50px;
  font-weight: 700;
  justify-self: end;
  margin-top: auto;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .contactus-page .kakao-ch-btn {
    width: 320px;
  }
  #root .contactus-page > .title-section > .title-wrapper {
    width: 688px;
  }
  #root .contactus-page > .contactus-section > .contactus-wrapper {
    width: 688px;
  }
  #root .contactus-page > .input-section > .inquiry-type-wrapper {
    width: 688px;
  }
  #root .contactus-page > .input-section > .inquiry-input-wrapper {
    width: 688px;
  }
  #root .contactus-page > .input-section > .inquiry-input-wrapper > .input-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .contactus-page > .input-section > .inquiry-input-wrapper > .input-wrapper > .right-box {
    margin-top: 48px;
  }
}
@media screen and (max-width: 767px) {
  #root .contactus-page > .title-section > .title-wrapper {
    width: 100%;
    padding: 0px 20px;
  }
  #root .contactus-page > .contactus-section > .contactus-wrapper {
    width: 100%;
    padding: 30px 20px;
  }
  #root .contactus-page > .contactus-section > .contactus-wrapper > .kakao-ch-btn {
    width: 320px;
  }
  #root .contactus-page > .input-section > .inquiry-type-wrapper {
    width: 100%;
  }
  #root .contactus-page > .input-section > .inquiry-type-wrapper > .inquiry-text {
    padding: 0px 20px;
  }
  #root .contactus-page > .input-section > .inquiry-type-wrapper > .btn-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 0px 20px;
    row-gap: 8px;
  }
  #root .contactus-page > .input-section > .inquiry-type-wrapper > .btn-wrapper > .inquiry-btn {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  #root .contactus-page > .input-section > .inquiry-input-wrapper {
    width: 100%;
  }
  #root .contactus-page > .input-section > .inquiry-input-wrapper > .input-wrapper {
    padding: 0px 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .contactus-page > .input-section > .inquiry-input-wrapper > .input-wrapper > .right-box {
    row-gap: 24px;
    margin-top: 48px;
  }
}