#root .wrapper > .about-card {
  width: 100%;
  height: 134px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid black;
}
#root .wrapper > .about-card > .about-box {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 40px;
  width: 1440px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#root .wrapper > .about-card > .about-box > .aboutcard-title-wrapper {
  width: 100%;
}
#root .wrapper > .about-card > .about-box > .aboutcard-title-wrapper > .aboutcard-title {
  font-size: 50px;
  font-weight: 400;
  white-space: pre-wrap;
  text-align: left;
}
#root .wrapper > .about-card > .about-box > .aboutcard-subtitle-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .wrapper > .about-card > .about-box > .aboutcard-subtitle-wrapper > .aboutcard-subtitle {
  font-size: 24px;
  font-weight: 700;
}
#root .wrapper > .about-card > .about-box > .aboutcard-subtitle-wrapper > .down-btn {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
#root .wrapper > .about-card > .about-box > .aboutcard-subtitle-wrapper > .active {
  -webkit-transform: rotateZ(180deg);
          transform: rotateZ(180deg);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .wrapper > .about-card {
    padding: 32px 0px;
  }
  #root .wrapper > .about-card > .about-box {
    width: 750px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: unset;
  }
  #root .wrapper > .about-card > .about-box > .aboutcard-title-wrapper {
    width: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: auto;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  #root .wrapper > .about-card > .about-box > .aboutcard-title-wrapper > .aboutcard-title {
    font-size: 33px;
    font-weight: 400;
  }
  #root .wrapper > .about-card > .about-box > .aboutcard-subtitle-wrapper {
    width: 100%;
    padding-right: 0;
  }
  #root .wrapper > .about-card > .about-box > .aboutcard-subtitle-wrapper > .aboutcard-subtitle {
    font-size: 20px;
  }
  #root .wrapper > .about-card > .about-box > .aboutcard-subtitle-wrapper > .down-btn {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
}
@media screen and (max-width: 767px) {
  #root .wrapper > .about-card {
    height: 100%;
    padding: 32px 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  #root .wrapper > .about-card > .about-box {
    width: 100%;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: unset;
  }
  #root .wrapper > .about-card > .about-box > .aboutcard-title-wrapper > .aboutcard-title {
    font-size: 40px;
    width: 252px;
    height: 96px;
  }
}