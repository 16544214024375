#root .display-img-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#root .display-img-card > .info-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: 1px solid black;
  border-top: none;
  row-gap: 8px;
  padding: 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .display-img-card > .info-wrapper > .title {
  font-size: 18px;
  font-weight: 700;
}
#root .display-img-card > .info-wrapper > .subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  white-space: pre;
}

@media screen and (max-width: 767px) {
  #root .display-img-card {
    width: 100%;
  }
  #root .display-img-card img {
    height: auto;
  }
}