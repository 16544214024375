@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    display: none;
  }
  60% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
    display: -webkit-box;
    display: flex;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    display: none;
  }
  60% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
@-webkit-keyframes drawCenter {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}
@keyframes drawCenter {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}
@-webkit-keyframes drawHeight {
  from {
    height: 0;
  }
  to {
    height: 700px;
  }
}
@keyframes drawHeight {
  from {
    height: 0;
  }
  to {
    height: 700px;
  }
}
@-webkit-keyframes drawWidth {
  from {
    height: 0;
  }
  to {
    height: 1200px;
  }
}
@keyframes drawWidth {
  from {
    height: 0;
  }
  to {
    height: 1200px;
  }
}
#root .aboutus-banner {
  width: 100%;
  height: 334px;
  background-color: black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 10px;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
}
#root .aboutus-banner > .aboutus-banner-title {
  font-size: 30px;
  font-weight: 700;
  white-space: pre;
  color: white;
  line-height: 39px;
  text-align: center;
}
#root .aboutus-banner > .aboutus-banner-subtitle {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: white;
  line-height: 25.2px;
  white-space: pre;
}
#root .aboutus-banner > .line-container {
  width: 1920px;
  position: absolute;
  z-index: 20;
  bottom: 0;
  height: 475px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
#root .aboutus-banner > .line-container .line {
  position: absolute;
  background-color: #ECE9E3;
}
#root .aboutus-banner > .line-container .line1 {
  left: 550px;
  top: 0;
  width: 1px;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  -webkit-transform: rotate(-40.9deg);
          transform: rotate(-40.9deg);
  -webkit-animation: drawHeight 1.5s ease-in-out forwards;
          animation: drawHeight 1.5s ease-in-out forwards;
}
#root .aboutus-banner > .line-container .line2 {
  top: 0;
  width: 1px;
  -webkit-animation: drawCenter 1.5s ease-in-out forwards;
          animation: drawCenter 1.5s ease-in-out forwards;
}
#root .aboutus-banner > .line-container .line3 {
  right: 550px;
  top: 0;
  width: 1px;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  -webkit-transform: rotate(40.9deg);
          transform: rotate(40.9deg);
  -webkit-animation: drawHeight 1.5s ease-in-out forwards;
          animation: drawHeight 1.5s ease-in-out forwards;
}
#root .aboutus-banner > .line-container .line4 {
  top: 100px;
  left: 0;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  width: 1px;
  -webkit-transform: rotate(-68.7deg);
          transform: rotate(-68.7deg);
  -webkit-animation: drawWidth 1.5s ease-in-out forwards;
          animation: drawWidth 1.5s ease-in-out forwards;
}
#root .aboutus-banner > .line-container .line5 {
  right: 0;
  top: 100px;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  -webkit-transform: rotate(68.7deg);
          transform: rotate(68.7deg);
  -webkit-animation: drawWidth 1.5s ease-in-out forwards;
          animation: drawWidth 1.5s ease-in-out forwards;
  width: 1px;
}

@media screen and (max-width: 768px) {
  #root .aboutus-banner {
    padding: 96px 0px;
    row-gap: 16px;
    height: 475px;
  }
}