#root .toast-msg-box {
  position: absolute;
  bottom: 20px;
  background-color: black;
  border-radius: 5px;
  width: 60%;
  height: 50px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .toast-msg-box > .msg {
  color: white;
  font-weight: 700;
  font-size: 16px;
}