@media screen and (min-width: 768px) {
  #root .residency-detail {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  #root .residency-detail > .detail-card {
    width: 728px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    background-color: white;
  }
  #root .residency-detail > .detail-card > .close-btn {
    position: absolute;
    top: 7px;
    right: 5px;
  }
  #root .residency-detail > .detail-card > .close-btn img {
    width: 44px;
    height: 44px;
  }
  #root .residency-detail > .detail-card > .title-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .residency-detail > .detail-card > .title-wrapper > .title {
    font-weight: 700;
  }
  #root .residency-detail > .detail-card > .img-wrapper {
    text-align: center;
    margin-top: 16px;
    position: relative;
    overflow: hidden;
  }
  #root .residency-detail > .detail-card > .img-wrapper img {
    width: 696px;
    height: 437px;
    overflow: hidden;
    -o-object-fit: contain;
       object-fit: contain;
  }
  #root .residency-detail > .detail-card > .glasses-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    z-index: 111;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-column-gap: 6px;
       -moz-column-gap: 6px;
            column-gap: 6px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding-bottom: 16px;
    margin-top: 16px;
  }
  #root .residency-detail > .detail-card > .glasses-wrapper > .circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border: 1px solid rgba(219, 219, 219, 0.7411764706);
    background-color: white;
  }
  #root .residency-detail > .detail-card > .glasses-wrapper > .share-btn {
    position: absolute;
    right: 16px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border: 1px solid rgba(219, 219, 219, 0.7411764706);
    background-color: white;
  }
  #root .residency-detail > .detail-card > .contact-btn {
    width: 100px;
    height: 40px;
    background-color: #000000;
    color: white;
    border-radius: 5px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-bottom: 16px;
    font-weight: bold;
  }
}
@media screen and (max-width: 767px) {
  #root .residency-detail {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  #root .residency-detail > .detail-card {
    width: 728px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }
  #root .residency-detail > .detail-card > .close-btn {
    position: absolute;
    top: 7px;
    right: 5px;
  }
  #root .residency-detail > .detail-card > .close-btn img {
    width: 44px;
    height: 44px;
  }
  #root .residency-detail > .detail-card > .title-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .residency-detail > .detail-card > .title-wrapper > .title {
    font-weight: 700;
  }
  #root .residency-detail > .detail-card > .img-wrapper {
    text-align: center;
    margin-top: 16px;
    position: relative;
    overflow: hidden;
  }
  #root .residency-detail > .detail-card > .img-wrapper img {
    width: 100vw;
    overflow: hidden;
    -o-object-fit: contain;
       object-fit: contain;
  }
  #root .residency-detail > .detail-card > .glasses-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    z-index: 111;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-column-gap: 6px;
       -moz-column-gap: 6px;
            column-gap: 6px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding-bottom: 16px;
    margin-top: 16px;
  }
  #root .residency-detail > .detail-card > .glasses-wrapper > .circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border: 1px solid rgba(219, 219, 219, 0.7411764706);
    background-color: white;
  }
  #root .residency-detail > .detail-card > .glasses-wrapper > .share-btn {
    position: absolute;
    right: 16px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border: 1px solid rgba(219, 219, 219, 0.7411764706);
    background-color: white;
  }
  #root .residency-detail > .detail-card > .contact-btn {
    width: 100px;
    height: 40px;
    background-color: #000000;
    color: white;
    border-radius: 5px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-weight: bold;
  }
}