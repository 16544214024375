#root .about-page {
  padding-top: 64px;
}
#root .about-page .section-wrapper .section-slide {
  max-height: 0;
  -webkit-transition: max-height 1s ease-in-out;
  transition: max-height 1s ease-in-out;
  overflow: hidden;
}
#root .about-page .section-wrapper > .active {
  max-height: 9000px;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}
#root .about-page .section-wrapper > .hide {
  -webkit-transition: max-height 1s ease-in-out;
  transition: max-height 1s ease-in-out;
  max-height: 0;
}