#root .author-img-card {
  position: relative;
  width: 256px;
  height: 412px;
  overflow: hidden;
}
#root .author-img-card img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#root .author-img-card > .background-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.8)));
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
}
#root .author-img-card > .author-button-wrapper {
  width: 100%;
  height: 27px;
  padding: 0px 16px;
  position: absolute;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  bottom: 30px;
  z-index: 1;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .author-img-card > .author-button-wrapper > .author-name {
  color: white;
  font-size: 15px;
  font-weight: 700;
}
#root .author-img-card > .author-button-wrapper > .arrow-btn {
  width: 23px;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .author-img-card {
    width: 475px;
    height: 367px;
  }
  #root .author-img-card > .author-button-wrapper {
    width: 450px;
  }
}
@media screen and (max-width: 767px) and (min-width: 360px) {
  #root .author-img-card {
    width: 100%;
    height: 247px;
  }
}